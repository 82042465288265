/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';



// start the Stimulus application
const $ = require('jquery');
global.$ = global.jQuery = $;

import { Modal, Carousel } from 'bootstrap';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
import Alert from 'bootstrap/js/dist/alert';

import 'aos/dist/aos.css';
import './styles/includes/css/cookieconsent.css';

import Lightbox from './styles/includes/js/lightbox';

$('.lightbox a').each(function(){
    $(this).click(function(){
        const lightbox = new Lightbox($(this),Modal, Carousel);
        return false;
    });
})

import './styles/includes/js/stickymenu';

const simpleParallax = require('simple-parallax-js');
let image = document.getElementsByClassName('img-parallax');
new simpleParallax(image, {
    delay: .6,
	transition: 'cubic-bezier(0,0,0,1)'
});





